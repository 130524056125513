import { makeAutoObservable, observable } from 'mobx';
import { getConfigMC, getRenewalsConfig, queryFunction } from '~/store/Query/api';
import request from '~/bin/httpRequest';

const genericFunction = (options, sessionIdDisable, actionCallback) => request.genericHandler(options, sessionIdDisable).then((res) => {
  let callback = { action: actionCallback, success: false };
  if (!res.error) {
    callback = {
      ...callback,
      data: res.data,
      success: true,
    };
  } else {
    callback = {
      ...callback,
      error: res.error,
    };
  }
  return callback;
});

class RenewalStore {

  installments = observable.set(0);
  renewalConfig = null;

  getCartInfo = observable.map();
  getCartConfig = observable.map();
  warnedUser = false;

  hasRenewalOrder = false;
  showReminder = false;
  reminderType = "DESKTOP"

  constructor() {
    makeAutoObservable(this);
  }

  changeReminderType(type) {

    this.reminderType = type;

  }

  closeReminder() {
    this.showReminder = false;
  }


  handleExistsRenewalOrderValidation(hasRenewalOrder, reminderType) {

    this.hasRenewalOrder = hasRenewalOrder;
    this.showReminder = hasRenewalOrder;
    this.reminderType = reminderType;

  }

  async getCart(init, loading) {
    const options = {
      method: 'GET',
      url: `${process.env.NEXT_PUBLIC_API}${process.env.NEXT_PUBLIC_MERCHANT}/cart`,
      loading,
    };
    const response = await genericFunction(options, null, 'cart');
    this.getCartInfo = response;
    if (init === 'user_warned') {
      this.warnedUser = true;
    } else if (init === 'user_not_warned') {
      this.warnedUser = false;
    }

    return response;

  }

  setWarnedUser(init) {
    if (init === 'user_warned') {
      this.warnedUser = true;
    } else if (init === 'user_not_warned') {
      this.warnedUser = false;
    }
  }

  async getConfig() {

    if (this.renewalConfig) {
      return this.renewalConfig;
    }

    const response = await queryFunction('renewalsConfig', getRenewalsConfig);

    if (response.success && response?.data?.data) {
      const data = response.data.data;
      this.renewalConfig = data;
      return data;
    }

    return null;

  }

  async setInstallments(installments) {
    console.log('--- installments', installments)
    this.installments = installments;
  }

  async getRenewalOrder() {

    const options = {
      method: 'GET',
      url: `${process.env.NEXT_PUBLIC_API}${process.env.NEXT_PUBLIC_MERCHANT}/renewal`,
    };

    const response = await genericFunction(options, null, 'getRenewalOrder');
    console.log('response', response);
    return response;

  }

  async createRenewalOrder(payload
  ) {
    const options = {
      method: 'POST',
      url: `${process.env.NEXT_PUBLIC_API}${process.env.NEXT_PUBLIC_MERCHANT}/renewal`,
      data: payload,
    };

    const response = await genericFunction(options, null, 'createRenewalOrder');
    return response;
  }

  async saveAddress(payload, orderID) {

    const options = {
      method: 'PUT',
      url: `${process.env.NEXT_PUBLIC_API}${process.env.NEXT_PUBLIC_MERCHANT}/renewal/${orderID}`,
      data: payload,
    };

    const response = await genericFunction(options, null, 'saveAddress');
    return response;

  }


  async getContractData(contractURL) {

    try {

      const baseURL = `${process.env.NEXT_PUBLIC_AMXGW_HOST}`;
      const url = `${baseURL}${contractURL}`;
  
      const options = {
        method: 'GET',
        url,
      };
  
      const response = await genericFunction(options, null, 'getContractData');
  
      if (response.success && response?.data?.data) {
        const data = response?.data?.data?.result?.data?.groupcontract;
        return JSON.parse(JSON.stringify(data));
      }
  
      return null;
      
    } catch (error) {
      console.log('error', error);
      return null
    }

  }


  async addShippingAddress(query, order_id) {
    const options = {
      method: 'PUT',
      url: `${process.env.NEXT_PUBLIC_API}${process.env.NEXT_PUBLIC_MERCHANT}/renewal/${order_id}`,
      data: query,
    };

    return genericFunction(options, null, 'shippingAddress');
  }

  

  async confirmOrderWithoutPayment(order_id) {
    const options = {
      method: 'POST',
      url: `${process.env.NEXT_PUBLIC_SERVICES}/payments/htech/generate/${process.env.NEXT_PUBLIC_MERCHANT}/renewal/${order_id}`,
      data: {},
    };

    return genericFunction(options, null, 'shippingAddress');
  }

  async confirmOrder(order_id) {
    const options = {
      method: 'POST',
      url: `${process.env.NEXT_PUBLIC_API}${process.env.NEXT_PUBLIC_MERCHANT}/renewal/checkout`,
      data: {
        "order_id": order_id
      },
    };

    return genericFunction(options, null, 'shippingAddress');
  }



}

export default new RenewalStore();
