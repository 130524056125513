//import blukai, removido
import useStores from '~/store/StoreHook';

const LoginBtn = () => {

  const { sessionStore, renewalStore } = useStores();

  const hasRenewalOrder = renewalStore.hasRenewalOrder;
  const showDesktopReminder = renewalStore.reminderType === 'DESKTOP';
  const showReminder = renewalStore.showReminder;

  const top = hasRenewalOrder && showReminder && showDesktopReminder ? '200%' : '';
  
  return (
    <div className="ui--login-btn ui-log-btn active ie-fix" style={{ top }}>
      <a
        className="ui--btn-login"
        onClick={() => {
          console.log('LOGIN')
          sessionStore.toggleAuthModal(true, 'SIGN_IN');
          //blukai function, removido
        }}
      >
        Iniciar Sesión
      </a>
      <a
        className="ui--btn-register"
        onClick={() => {
          sessionStore.toggleAuthModal(true, 'SIGN_UP');
          //blukai function, removido
        }}
      >
        Regístrate
      </a>
    </div>
  )

};

export default LoginBtn;
