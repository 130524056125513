import React from "react";
import './Checkbox.scss'

export const Checkbox = ({
  checked = false,
  onChange = () => {},
  children = null,
  appearance = 'default',
  id = '',
  customStyle = {}
}) => {

  if (appearance === 'ghost') {
    return (
      <label id={id} className="app-form-control-ghost" style={customStyle}>
        <input checked={checked} onChange={() => onChange(!checked)} type="checkbox" name="checkbox" />
        {children}
      </label>
    )
  }

  return (
    <label id={id} className="app-form-control" style={customStyle}>
      <input checked={checked} onChange={() => onChange(!checked)} 
      type="checkbox" name="checkbox" />
      {children}
    </label>
  )

}