import { useEffect, useState } from 'react';
import CloseIcon from '~/helpers/js_svg/close-icon';
import '../../sass/modules/_auth.scss';
import SignupForm from './Signup';
import SigninForm from './Signin';
import Recover from './Recover';



function AuthUI({
  isOpen,
  onClose = () => {},
  handleSignup = () => {},
  handleSignin = () => {},
  type = null,
  changeView = (view) => {},
  error = null
}) {

  useEffect(() => {
    const handleEscapeKey = (e) => {
      if (isOpen && e.key === 'Escape') {
        onClose();
      }
    };

    const handleClickOutside = (e) => {
      if (isOpen && !e.target.closest('.auth-modal-content')) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener('keydown', handleEscapeKey);
      document.addEventListener('mousedown', handleClickOutside);

      return () => {
        document.removeEventListener('keydown', handleEscapeKey);
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }
  }, [isOpen, onClose]);


  const goToSignin = () => {

    changeView('SIGN_IN')
  }

  const goToSignup = () => {
    changeView('SIGN_UP')
  }

  const goToPasswordRecover = () => {
    changeView('RECOVER')
  }

  useEffect(() => {

    if ( ['SIGN_IN', 'SIGN_UP', 'RECOVER'].includes(type) ) {
      document.body.style.overflow = 'hidden';
    }

    return () => {
      document.body.style.overflow = 'auto';
    }

  }, [type])


  return (
    <div className="auth-modal-overlay">
      <div className="auth-modal-content">

        <button className="auth-modal-close" onClick={onClose}>
          <CloseIcon/>
        </button>

        {
          type === 'SIGN_UP' && (
            <SignupForm
              goToSignin={goToSignin}
              onSubmitForm={handleSignup}
              error={error}
              goToPasswordRecover={goToPasswordRecover}
            />
          )
        }

        {
          type === 'SIGN_IN' && (
            <SigninForm
              goToSignup={goToSignup}
              onSubmitForm={handleSignin}
              error={error}
              goToPasswordRecover={goToPasswordRecover}
            />
          )
        }

        {
          type === 'RECOVER' && (
            <Recover
              goToSignin={goToSignin}
              onSubmitForm={handleSignin}
              error={error}
              goToPasswordRecover={goToPasswordRecover}
            />
          )
        }

      </div>
    </div>
  );

}

export default AuthUI;
