import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import PropTypes from 'prop-types';
import $ from 'jquery';
import Cookies from 'universal-cookie';

// Helpers
import validate from '~/helpers/validate_form';

// UI
import UI from './Login.ui';

class Login extends Component {
  static contextTypes = {
    router: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.state = {
      error: '',
    };

    this.internalData = {
      email: '',
      password: '',
    };
    // Binds
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    validate();
    this.clearError();
    $('#login-pic').removeClass('margin-top');
  }

  clearError() {
    this.setState({ error: '' });
  }

  handleChange(e, data) {
    const keys = data.split('.');
    const loginForm = this;
    loginForm[keys[0]][keys[1]] = e.target.value;
    this.setState(loginForm);
  }

  handleData() {
    this.preValidateGuestSession();
  }

  preValidateGuestSession = async () => {
    const cookies = new Cookies();
    if (!cookies.get('Session-Id')) {
      const guestSession = await this.props.sessionStore.guestSession();
      if (guestSession && guestSession.data && guestSession.data.data) {
        // set cookie.
        cookies.set('Session-Id', guestSession.data.data.session_id, {
          path: '/',
        });
      }
    }
    this.performLogin();
  };

  performLogin() {
    const query = {
      ...this.internalData,
      email: this.internalData.email.toUpperCase(),
    };

    this.props.sessionStore.personLogin(query).then((res) => {
      //console.debug('response', res);
      if (res.success && res.action === 'personLogin') {
        const medalia_email = this.internalData.email.toLowerCase();
        const medalia_user_login = true;
        const medalia_name = null;
        const medalia_order_id = null;
        const medalia_order_state = null;
        const medalia_order_items = null;
        localStorage.setItem('medallia_email', medalia_email);
        localStorage.setItem('medallia_user_login', medalia_user_login);
        localStorage.setItem('medallia_name', medalia_name);
        localStorage.setItem('medallia_order_id', medalia_order_id);
        localStorage.setItem('medallia_order_state', medalia_order_state);
        localStorage.setItem('medallia_order_items', medalia_order_items);
        window.location.reload();
        // const { pathname } = this.props.history.location;
        // const strPathName = /\/product\//gi;
        // if (pathname === '/cart' || pathname === '/cart/login' || pathname.match(strPathName)) {
        //   // debugger;
        //   window.location.reload();
        // } else {
        //   this.props.history.push('/cart');
        // }
        this.props.closeModals();
      } else if (!res.success && res.action === 'personLogin') {
        if (
          res
          && res.error
          && res.error.data
          && res.error.data.errors[0].errorMessage
          === 'User_not_registered: User_not_registered_in_SSO'
        ) {
          this.setState({ error: 'Usuario no registrado.' });
        } else if (
          res
          && res.error
          && res.error.data
          && res.error.data.errors[0].errorMessage
          === 'access_denied: Password_not_valid'
        ) {
          this.setState({ error: 'Contraseña incorrecta.' });
        } else if (
          res
          && res.error
          && res.error.data
          && res.error.data.errors[0].errorMessage
          === 'The specified logon ID or password are not correct.Verify the information provided and log in again.'
        ) {
          this.setState({
            error: 'Usuario o contraseña incorrectos, favor de verificar.',
          });
        } else if (
          res
          && res.error
          && res.error.data
          && res.error.data.errors[0].errorMessage
          === 'user blocked: Too many login atempts, wait for: 15 minutes'
        ) {
          this.setState({
            error: 'Usuario bloqueado, intente de nuevo en 15 minutos.',
          });
        } else if (
          res
          && res.error
          && res.error.data
          && res.error.data.errors[0].errorMessage
          === 'user blocked: Too many login atempts, wait for: 1 hour'
        ) {
          this.setState({
            error: 'Usuario bloqueado, intente de nuevo en 1 hora.',
          });
        } else if (
          res
          && res.error
          && res.error.data
          && res.error.data.errors[0].errorMessage
          === 'user blocked: Too many login atempts, wait for: 12 hours'
        ) {
          this.setState({
            error: 'Usuario bloqueado, intente de nuevo en 12 horas.',
          });
        } else if (
          res
          && res.error
          && res.error.data
          && res.error.status === (500 || 502 || 503 || 504)
        ) {
          this.setState({
            error: 'Error al iniciar sesión, contacte a soporte.',
          });
        } else {
          this.setState({
            error:
              'Se ha generado un error. Intente de nuevo o contacte a soporte.',
          });
        }
        // if (res && res.error && res.error.data && res.error.data.errors && res.error.data.errors[0].errorMessage) this.setState({ error: res.error.data.errors[0].errorMessage });
      }
    });
  }

  handleSubmit(e) {
    e.preventDefault();
    const isIE = navigator.userAgent.indexOf('MSIE') !== -1
      || navigator.appVersion.indexOf('Trident/') > -1;
    if (isIE) this.handleData();
    else if (!isIE && $("form[name='login']").valid()) this.handleData();
  }

  render() {
    return (
      <UI
        loginForm={this.internalData}
        handleChange={this.handleChange}
        handleSubmit={this.handleSubmit}
        modalType={this.props.modalType}
        closeModals={this.props.closeModals}
        error={this.state.error}
      />
    );
  }
}

Login.propTypes = {
  sessionStore: PropTypes.shape({
    personLogin: PropTypes.func,
  }).isRequired,
  modalType: PropTypes.func,
  closeModals: PropTypes.func.isRequired,
};

Login.defaultProps = {
  modalType: () => {},
};

export default inject('sessionStore')(observer(Login));
