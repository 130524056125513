import { FieldValidator } from "~/helpers/field-validators";

const trim = true;

export const signupValidations = (form, messages = {}) => {

  const validations = {
    firstName: [
      {
        validator: FieldValidator.required(form.firstName, trim),
        message: 'Su nombre no fue capturado.',
      },
    ],
    lastName: [
      {
        validator: FieldValidator.required(form.lastName, trim),
        message: 'Sus apellidos no fueron capturados.',
      },
    ],
    email: [
      {
        validator: FieldValidator.required(form.email, trim),
        message: 'Por favor, introduzca su dirección de correo electrónico.'
      },
      {
        validator: FieldValidator.isEmail(form.email, trim),
        message: 'Por favor, introduzca un correo electrónico válido.',
      },
    ],
    password: [
      {
        validator: FieldValidator.required(form.password, trim),
        message: 'Por favor, introduzca una contraseña.',
      },
      {
        validator: FieldValidator.validPassword(form.password, trim),
        message: 'Por favor, introduzca una contraseña válida.',
      },
    ],
    confirmPassword: [
      {
        validator: FieldValidator.required(form.confirmPassword, trim),
        message: 'Por favor, introduzca nuevamente su contraseña.',
      },
      {
        validator: FieldValidator.passwordMatch(form.confirmPassword, form.password, trim),
        message: 'La contraseña no coincide, intente nuevamente.',
      },
    ],
    termsAndConditions: [
      {
        validator: FieldValidator.isTrue(form.termsAndConditions),
        message: 'Por favor, acepte los términos y condiciones.',
      },
    ],
  };

  const errors = {};
  let hasErrors = false;

  Object.keys(validations).forEach((key) => {
    const fieldErrors = validations[key]
      .filter((validation) => !validation.validator)
      .map((validation) => validation.message);

    if (fieldErrors.length > 0) {
      errors[key] = fieldErrors;
      hasErrors = true;
    }
  });

  return {
    errors,
    hasErrors,
  };

}

export const recoverValidations = (form, messages = {}) => {

  const validations = {
    email: [
      {
        validator: FieldValidator.required(form.email, trim),
        message: 'Por favor, introduzca su dirección de correo electrónico.'
      },
      {
        validator: FieldValidator.isEmail(form.email, trim),
        message: 'Por favor, introduzca un correo electrónico válido.',
      },
    ]
  };

  const errors = {};
  let hasErrors = false;

  Object.keys(validations).forEach((key) => {
    const fieldErrors = validations[key]
      .filter((validation) => !validation.validator)
      .map((validation) => validation.message);

    if (fieldErrors.length > 0) {
      errors[key] = fieldErrors;
      hasErrors = true;
    }
  });

  return {
    errors,
    hasErrors,
  };

}

export const signinValidations = (form, messages = {}) => {

  const validations = {
    email: [
      {
        validator: FieldValidator.required(form.email, trim),
        message: 'Por favor, introduzca su dirección de correo electrónico.'
      },
      {
        validator: FieldValidator.isEmail(form.email, trim),
        message: 'Por favor, introduzca un correo electrónico válido.',
      },
    ],
    password: [
      {
        validator: FieldValidator.required(form.password, trim),
        message: 'Por favor, introduzca una contraseña.',
      }
    ]
  }

  const errors = {};
  let hasErrors = false;

  Object.keys(validations).forEach((key) => {
    const fieldErrors = validations[key]
      .filter((validation) => !validation.validator)
      .map((validation) => validation.message);

    if (fieldErrors.length > 0) {
      errors[key] = fieldErrors;
      hasErrors = true;
    }
  });

  return {
    errors,
    hasErrors,
  };

}