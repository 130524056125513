export class FieldValidator {

  constructor() {
    this.errors = {};
  }

  static required(value, trim = false) {

    console.log({value})
    const valueToValidate = trim ? value?.trim() : value;
    const isValid = valueToValidate ? true : false;

    return isValid; 

  }

  static isEmail(value, trim = false) {
 
    const valueToValidate = trim ? value?.trim() : value;

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValid    = emailRegex.test(valueToValidate);

    return isValid;
 
  }

  static validPassword(value, trim = false) {
 
    const valueToValidate = trim ? value?.trim() : value;
    const passwordRegex   = /^(?=.*[0-9])(?=.*[A-Z])(?=.*[!@#$%^*+]).{8,16}$/;
    const isValid         = passwordRegex.test(valueToValidate);

    return isValid;
 
  }

  static passwordMatch(value, valueToMatch, trim = false) {

    const valueToValidate = trim ? value?.trim() : value;
    const isValid = valueToValidate === valueToMatch;

    return isValid;

  }

  static runValidators(validators = []) {

    let hasError = false;
    let error = null;

    for (let i = 0; i < validators.length; i++) {

      if (hasError) {
        break;
      }

      const { validator, message } = validators[i];

      if (!validator) {
        hasError = true;
        error = message;
        break;
      }

    }

    return { hasError, error };

  }

  static isTrue(value) {
    
    const isValid = value === true;

    return isValid;

  }

}