'use client'
import React from 'react';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import listSelector from '~/helpers/liselector';

import '~/sass/modules/_search.scss';
import { withRouter } from '#/renderer/router';

// const hide = {
//   display: 'none',
// };

const SearchResults = ({ data, listSelected }) => (
  <li
    className="autosuggest"
    role="presentation"
    tabIndex="-1"
    onClick={(e) => listSelected(e, data.term)}
  >
    <a
      onClick={() => {
        document.querySelector("#search-box").value = "";
      }}
    >
      {data.term}
    </a>
  </li>
);

class SearchBox extends React.Component {
  static contextTypes = {
    router: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.internalData = {
      search: "",
    };

    this.state = {
      autoSuggest: [],
      search: '',
      showBar: false,
    };
  }

  componentDidMount() {
    listSelector(this.completeSentence);
  }

  /**
   @augments new information to search
   */
  completeSentence = (data) => {
    this.setState({ search: data });
  };

  handleChange = (e) => {
    if (e.target.value && e.target.value.length && e.target.value.length >= 3) {
      this.props.catalogStore
        .autoSuggest({ keyword: e.target.value })
        .then((res) => {
          if (res.data && res.success) {
            this.setState({ autoSuggest: res.data.data.Keyword });
          }
        });
    }
    this.setState({ search: e.target.value });
  };

  handleSubmit = (e, data) => {
    e.preventDefault();
    const searchData = data ?? this.state.search;
    if (searchData) {
      this.setState({ autoSuggest: [], search: '' });
      this.props.history.push(`/search/${searchData}`);
    } else {
      this.props.history.push(`/search/${this.state.search}`);
    }
  };

  blurHandler = () => {
    setTimeout(() => {
      this.setState({ autoSuggest: [] });
    }, 600);
  };

  render() {
    const searchTerm = this.state.search;
    let searchResult;
    if (
      this.state.autoSuggest.length &&
      searchTerm !== "" &&
      !searchTerm.startsWith("tar") &&
      !searchTerm.startsWith("rec")
    ) {
      searchResult = this.state.autoSuggest.map((res, i) => {
        const index = i;
        return (
          <SearchResults
            key={index}
            data={res}
            listSelected={this.handleSubmit}
          />
        );
      });
    } else searchResult = [];
    //const [showSearch, setShowSearch] = useState(false);
    return (
      <form onSubmit={this.handleSubmit} className="search--box">
        <button
          type="button"
          className="ButtonSearch"
          onClick={() => this.props.toggle && this.setState(prev => ({ showBar: !prev.showBar }))}
        >
          <i className="ico-search" aria-hidden />
        </button>
        <label className={`labelSearchBar ${this.props.toggle && this.state.showBar ? 'showBar' : ''}`}>
          {/* <button type="button" onClick={() => setShowSearch(!showSearch)}>
            <i className="fas fa-search" />
          </button> */}
          <input
            id="search-box"
            type="text"
            placeholder={this.props.placeholder || 'Buscar productos'}
            value={this.state.search}
            onChange={this.handleChange}
            onBlur={this.blurHandler}
            autoComplete="off"
          />
        </label>
        <a className="search--box-reset" />
        <button type="submit">BUSCAR</button>
        <div id="x-search-list">
          <ul id="search-list" className="search--box-results">
            {searchResult}
          </ul>
        </div>
      </form>
    );
  }
}

SearchBox.propTypes = {
  catalogStore: PropTypes.shape({
    search: PropTypes.func,
    autoSuggest: PropTypes.func,
    autoSuggestResult: PropTypes.shape({}),
  }).isRequired,
};

SearchResults.propTypes = {
  data: PropTypes.shape({}).isRequired,
  listSelected: PropTypes.func.isRequired,
};

export default inject("catalogStore")(observer(withRouter(SearchBox)));
