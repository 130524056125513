'use client';
import he from 'he';
import { iconClub, iconEntretenimiento, iconHogar, iconMovil, iconPromociones, SVGiconIOT } from '~/helpers/svg';
import { Link } from '#/renderer/router';
import CartQuantity from '~/components/Headers/CartQuantity';
import SeccionesHeaderMobile from '~/components/Headers/SeccionesHeaderMobile';
import { usePageContext } from '#/renderer/usePageContext';
import {
  handelToggleSubItemSidebar,
  handleCloseMenuMobile,
  handleCloseSideBar,
  handleMenuLinks,
  handleMenuToggle,
  handleSubmenuMobile,
  handleSubmenuMobileTienda,
  handleToogleSideBar,
} from '~/components/Headers/handlers';
import Session from '~/components/Headers/Session';
import Search from './Search';
import { Fragment } from 'react';

const addChildren = (parent) => {
  const children = [];
  const getChildren = parent && parent.Hijos && parent.Hijos.length;
  const theChildren = parent.Hijos;
  if (getChildren) {
    for (let c = 0; c < theChildren.length; c += 1) {
      const child = theChildren[c];
      const link = he.decode(child.URL);
      const label = he.decode(child.Descripcion);
      const key = child.Unico;
      const subchildren = addChildren(child);
      if (subchildren.length) {
        children.push(
          <li key={`menu-${key}`}>
            <a href={link}>{label}</a>
            <ul>{subchildren}</ul>
          </li>,
        );
      } else {
        children.push(
          <li key={`menu-${key}`}>
            <a href={link}>{label}</a>
          </li>,
        );
      }
    }
  }
  return children;
};

export default function NavMenuLinks({ layoutData }) {
  const { pageProps: page } = usePageContext();
  const isDesktop = !page.isMobile;
  const optHeader = [];
  const sections = [];
  if (layoutData) {
    for (let i = 0; i < layoutData.MenuHeader.length; i += 1) {
      let item = layoutData.MenuHeader[i];
      const subItems = item.Hijos.map((item2, index2) => {
        const innerItems = item2.Hijos.map((item3, index) => (
          // <li key={index}>
          //   <a className='target-option' target={item3.Target} href={item3.URL}>
          //     {item3.Titulo}
          //   </a>
          // </li>
          <div key={index} className='display-innerItem'>
            <a href={item3.URL} target={item3.Target}>{item3.Titulo}</a>
          </div>
        ));
        return (
          <Fragment key={index2}>
            <ul className={`subMenu-item item-${index2}`}>
              <li onClick={() => { handelToggleSubItemSidebar(index2) }}>
                <a href={item2.URL} target={item2.Target}>
                  {item2.Titulo}
                </a>
                <i className='ico-chevron-down'></i>
              </li>
              {innerItems}
            </ul>
          </Fragment>
        );
      })
      let element = (
        <Fragment key={i}>
          <li className={`menu-body-items-list-item item-${item.Titulo}`}>
            <a href={item.URL} target={item.Target}>{item.Titulo}</a>
            <i className='ico-chevron-right' onClick={() => { handleToogleSideBar(item.Titulo) }}></i>
          </li>
          <div className={`subMenu subMenu-${item.Titulo}`}>
            <div className='subMenu-tittle'>
              <a href={item.URL} target={item.Target}>{item.Titulo}</a>
              <div className='backMenu' onClick={handleCloseSideBar}>
                <i className='ico-arrow-left'></i>
                <span>Regresar</span>
              </div>
            </div>
            {subItems}
          </div>
        </Fragment>
      );
      optHeader.push(element)
    }
    for (let i = 0; i < layoutData.Secciones.length; i += 1) {
      let item = layoutData.Secciones[i];
      let element = (
        <li key={i}><a href={item.URL} target={item.Target}>{item.Titulo}</a></li>
      );
      sections.push(element)
    }

  }
  // const headers = layoutData.SeccionesHeader?.[0]?.Hijos ?? [];
  // const getData = headers?.[0];
  // const mobileMiClaro = layoutData.MiClaro ? (
  //   <a href={he.decode(layoutData.MiClaro)} className="nav--menu-title wine miclaro">
  //     <span>Mi Claro</span>
  //   </a>
  // ) : null;

  // const slogan = layoutData.Eslogan ? <p>{he.decode(layoutData.Eslogan.replace(/&amp;/g, '&'))}</p> : null;

  // let servicesNav;
  // const output = [];
  // let tiendaNav;
  // const tiendaOutput = [];
  // if (getData) {
  //   const data = getData.Hijos;
  //   const iconList = [iconMovil, iconHogar, iconClub, <SVGiconIOT key={3} />, iconEntretenimiento, iconPromociones];
  //   for (let i = 0; i < data.length; i += 1) {
  //     const parent = data[i];
  //     const children = addChildren(parent);
  //     const block = (
  //       <ul className="nav--menu-links" key={parent.Unico}>
  //         <li className="desk icon">{iconList[i]}</li>
  //         <li>
  //           <a href={he.decode(parent.URL)}>
  //             <b>{he.decode(parent.Descripcion)}</b>
  //           </a>
  //         </li>
  //         {children}
  //       </ul>
  //     );
  //     output.push(block);
  //     servicesNav = <nav className="nav--menu-list">{output}</nav>;
  //   }

  //   let dataTienda = [];
  //   for (let i = 0; i < headers.length; i += 1) {
  //     if (headers[i].Descripcion.toLowerCase().trim() === 'tienda') {
  //       dataTienda = headers[i].Hijos;
  //       break;
  //     }
  //   }

  //   for (let dti = 0; dti < dataTienda.length; dti += 1) {
  //     const tiendaElement = dataTienda[dti];
  //     const children = addChildren(tiendaElement);
  //     const block = (
  //       <ul className="nav--menu-links-tienda" key={tiendaElement.Unico}>
  //         <li>
  //           <a href={he.decode(tiendaElement.URL)}>
  //             <b>{he.decode(tiendaElement.Descripcion)}</b>
  //           </a>
  //         </li>
  //         {children}
  //       </ul>
  //     );
  //     tiendaOutput.push(block);
  //     tiendaNav = <nav className="nav--menu-list-tienda">{tiendaOutput}</nav>;
  //   }
  // }

  // const getSeccionesHeader = layoutData.SeccionesHeader?.[0]?.Hijos ?? [];

  return (
    <div className="nav--menu" onMouseLeave={isDesktop ? handleMenuLinks : null}>
      <div className='nav--menu-tittle'>
        <p>Menu</p>
        <i className='ico-x' onClick={handleCloseMenuMobile}></i>
      </div>
      <div className='nav--menu-body'>
        <ul className='menu-body-head'>
          {sections}
        </ul>
        <div className='menu-body-search'>
          <Search />
        </div>
        <div className='menu-body-items'>
          <ul className='menu-body-items-list'>
            {optHeader}
          </ul>

          {/* <a className="item-mobile" onClick={handleSubmenuMobile}>
            <span>Servicios</span>
            <span className="mobile-icon icon-list fa-plus" />
          </a> */}

        </div>
      </div>
      <aside className="nav--menu-slogan">{"slogan"}</aside>
      <Session />
    </div>
  );
}
