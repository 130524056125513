import React, { useState } from 'react';
import '../../sass/modules/_auth.scss';
import Button from '../Button/Button';
import { Checkbox } from '../Checkbox/Checkbox';
import { Input } from '../Input/Input';
import { signupValidations } from './validations';
import Notification from '~/containers/Views/Notification/Notification.ui';


const SignupForm = ({
  goToSignin = () => {},
  onSubmitForm = (payload) => {},
  error = null,
  goToPasswordRecover = () => {},
}) => {

  const [ errors, setErrors ] = useState({});
  const [ formState, setFormState ] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    confirmPassword: '',
    termsAndConditions: false,
  });

  const passwordReqs = [
    "Entre 8 y 16 caracteres.",
    "Al menos un número (0-9) y una letra mayúscula (A-Z)",
    "Incluir uno de los siguientes símbolos: !, @, #, $, %, *, +.",
  ];

  const handleInputChange = (field, value) => {

    setFormState({
      ...formState,
      [field]: value,
    });
  }

  const onSubmit = () => {

    const formValidation = signupValidations(formState);

    if (formValidation.hasErrors) {
      setErrors(formValidation.errors);
      return;
    }

    setErrors({});
    onSubmitForm(formState);

  }

  React.useEffect(() => {
    const handleDocumentClick = (e) => {
      if (e.target.classList.contains('reset-password')) {
        goToPasswordRecover();
      }
    }
    document.addEventListener('click', handleDocumentClick);
    return () => document.removeEventListener('click', handleDocumentClick);
  }, []);

  return (
    <React.Fragment>

      <div className='auth-header'>
        <h1 className='auth-header--title'> Regístrate </h1>
        <div className='auth-header--divider-yellow'></div>
        <p className='auth-header--description'>
        Recuerda que puedes utilizar la misma cuenta y contraseña de Mi Claro para acceder a Tienda en Línea.
        </p>
      </div>

      <div className='auth-modal-content-card--signup'>

        <div className="auth-modal-content-body">

          <div className='login-form'>

            <Input
              label="Nombres"
              name='firstName'
              onChange={v => handleInputChange('firstName', v)}
              value={formState.firstName}
              error={errors?.firstName}
              />

            <Input
              label="Apellidos"
              name='lastName'
              onChange={v => handleInputChange('lastName', v)}
              value={formState.lastName}
              error={errors?.lastName}
              />

            <Input
              label="Correo electrónico"
              name='email'
              onChange={v => handleInputChange('email', v)}
              value={formState.email}
              error={errors?.email}
              />

            <Input
              type='password'
              label="Contraseña"
              name='password'
              onChange={v => handleInputChange('password', v)}
              value={formState.password}
              error={errors?.password}
              />

            <Input
              type='password'
              label="Confirmar contraseña"
              name='confirmPassword'
              onChange={v => handleInputChange('confirmPassword', v)}
              value={formState.confirmPassword}
              error={errors?.confirmPassword}
              />

          </div>

          <div className='password-requirements-container'>

            <p className='password-requirements-container--title'>Personalizar tu contraseña</p>
            <p className='password-requirements-container--subtitle'>Recuerda que debe tener:</p>

            <div className='password-requirements-container--list'>

              {
                passwordReqs.map(req => (
                  <div className='password-requirements-container--list--item'>
                    <div className='password-requirements-container--list--item--dot'></div>
                    <span className='password-requirements-container--list--item--text'>{req}</span>
                  </div>
                ))
              }

            </div>

          </div>

          <div>
            <Checkbox
              checked={formState.termsAndConditions}
              onChange={(value) => setFormState({ ...formState, termsAndConditions: value })}
              >
              <span style={{ fontSize: '12px', color: '#424242', fontWeight: 400 }}>Al continuar estarás aceptando los <a href='/terms' target='_blank' style={{ color: '#0097a9', fontWeight: '500' }}>Términos y Condiciones</a></span>
            </Checkbox>
          </div>

          {
            errors?.termsAndConditions && (
              <div>
                <span style={{ color: '#da291c', fontSize: '12px', fontWeight: 500 }}>{errors?.termsAndConditions}</span>
              </div>
            )
          }

          <div className="auth-modal-actions-buttons">

            <Button
              color="red"
              onClick={onSubmit}
              autoWidth="100%"
              appareance="ghost"
              customStyles={{ fontWeight: 700 }}
              >
              Continuar
            </Button>
          </div>

          <div className='go-to-signin'>
            <span className='go-to-signin--text'>¿Ya tienes una cuenta?</span>
            <span className='go-to-signin--link' onClick={goToSignin}>Iniciar Sesión</span>
          </div>
          <div>
            { error && <Notification title={<div dangerouslySetInnerHTML={{ __html: error }}></div>} /> }
          </div>
        </div>

      </div>
    </React.Fragment>
  )

}

export default SignupForm;
