"use client"
import { useState } from 'react';
// import '../../sass/modules/_auth.scss';
import { withRouter } from "#/renderer/router";
import { inject, observer } from 'mobx-react';
import analytics from '~/helpers/analytics';
import useStores from '~/store/StoreHook';
import AuthUI from './Auth.ui';


function Auth(props) {

  const [error, setError] = useState(null);
  // const { sessionStore, accountStore, renewalStore } = props;
  const { sessionStore, accountStore, renewalStore } = useStores();
  const authModal = sessionStore.authModal

  if (!authModal.show) {
    return <></>
  }

  const handleClose = () => {
    sessionStore.toggleAuthModal(false, null)
  }

  const handleChangeView = (view) => {
    setError(null);
    sessionStore.toggleAuthModal(true, view)
  }

  const handleLogin = (payload = {}) => {

    setError(null);

    const query = {
      password: payload?.password,
      email: payload?.email?.toUpperCase(),
    };

    console.log('START LOGIN')

    sessionStore.personLogin(query).then((res) => {

      console.log('END LOGIN', res)

      if (res.success && res.action === 'personLogin') {

        const medalia_email = payload.email.toLowerCase();
        const medalia_user_login = true;
        const medalia_name = null;
        const medalia_order_id = null;
        const medalia_order_state = null;
        const medalia_order_items = null;

        localStorage.setItem('medallia_email', medalia_email);
        localStorage.setItem('medallia_user_login', medalia_user_login);
        localStorage.setItem('medallia_name', medalia_name);
        localStorage.setItem('medallia_order_id', medalia_order_id);
        localStorage.setItem('medallia_order_state', medalia_order_state);
        localStorage.setItem('medallia_order_items', medalia_order_items);

        handleClose();

        const { pathname } = props.history.location;
        const strPathName = /\/choosecontract/gi;
        const renewalsMobileURL = 'renewals-mobile';
        if (pathname.match(strPathName) && sessionStore.contract && sessionStore.contract.plan) {
          const contract = JSON.parse(JSON.stringify(sessionStore.contract));
          props.history.push({ pathname: '/renewals/contract/info', state: { contract } });
        } else if ( pathname.includes(renewalsMobileURL) ) {
          handleClose();
        } else {
          window.location.reload();
        }

      } else if (!res.success && res.action === 'personLogin') {

        if (
          res &&
          res.error &&
          res.error.data &&
          res.error.data.errors[0].errorMessage === 'User_not_registered: User_not_registered_in_SSO'
        ) {
           setError('Usuario no registrado.')
        } else if (
          res &&
          res.error &&
          res.error.data &&
          res.error.data.errors[0].errorMessage === 'access_denied: Password_not_valid'
        ) {

          setError('Contraseña incorrecta.')

        } else if (
          res &&
          res.error &&
          res.error.data &&
          res.error.data.errors[0].errorMessage === 'The specified logon ID or password are not correct.Verify the information provided and log in again.'
        ) {

          setError('Usuario o contraseña incorrectos, favor de verificar.');

        } else if (
          res &&
          res.error &&
          res.error.data &&
          res.error.data.errors[0].errorMessage === 'user blocked: Too many login atempts, wait for: 15 minutes'
        ) {

          setError('Usuario bloqueado, intente de nuevo en 15 minutos.');

        } else if (
          res &&
          res.error &&
          res.error.data &&
          res.error.data.errors[0].errorMessage === 'user blocked: Too many login atempts, wait for: 1 hour'
        ) {

          setError('Usuario bloqueado, intente de nuevo en 1 hora.')

        } else if (
          res &&
          res.error &&
          res.error.data &&
          res.error.data.errors[0].errorMessage ===
          'user blocked: Too many login atempts, wait for: 12 hours'
        ) {

          setError('Usuario bloqueado, intente de nuevo en 12 horas.')

        } else if (
          res &&
          res.error &&
          res.error.data &&
          res.error.status === (500 || 502 || 503 || 504)
        ) {

          setError('Error al iniciar sesión, contacte a soporte.');


        } else {

          setError('Se ha generado un error. Intente de nuevo o contacte a soporte.')

        }

      }
    });
  }

  const handleSignup = (payload) => {

    setError(null);

    const dpi = (Date.now()).toString();
    const preferredCurrency = `${process.env.NEXT_PUBLIC_CURRENCY.toUpperCase()}`;
    const country = 'GT';

    const query = {
      firstName: payload.firstName,
      lastName: payload.lastName,
      email: payload.email.toUpperCase(),
      password: payload.password,
      dpi: dpi,
      preferredCurrency: preferredCurrency,
      country: country,
      birthDate: null,
      gender: null,
    };

    const queryPerson = {
      first_name: payload.firstName,
      last_name: payload.lastName,
      date_of_birth: '',
      email_1: payload.email,
      gender: '',
      mobile_country_code: '',
      mobile_phone_1: '',
      mobile_phone_country: '',
      phone_1: '',
    };


    sessionStore.personRegister(query).then((res) => {

      if (res.success && res.action === 'personRegister') {

        let medalia_email;
        let medalia_name;
        const datos = JSON.parse(res.data.config.data);

        if (datos) {
          medalia_email = datos.email.toLowerCase();
          medalia_name = `${datos.firstName} ${datos.lastName}`;
        } else {
          medalia_email = null;
          medalia_name = null;
        }

        const medalia_user_login = true;
        const medalia_order_id = null;
        const medalia_order_state = null;
        const medalia_order_items = null;
        localStorage.setItem('medallia_email', medalia_email);
        localStorage.setItem('medallia_user_login', medalia_user_login);
        localStorage.setItem('medallia_name', medalia_name);
        localStorage.setItem('medallia_order_id', medalia_order_id);
        localStorage.setItem('medallia_order_state', medalia_order_state);
        localStorage.setItem('medallia_order_items', medalia_order_items);

        handleClose();

        accountStore.profilePerson(queryPerson);
        analytics.usuarioCreado(query.firstName, query.lastName);

        const { pathname } = props.history.location;
        const strPathName = /\/choosecontract/gi;

        if (pathname.match(strPathName) && sessionStore.contract && sessionStore.contract.plan) {
          const contract = JSON.parse(JSON.stringify(sessionStore.contract));
          props.history.push({ pathname: '/renewals/contract/info', state: { contract } });
        } else {
          setTimeout(() => { window.location.reload(); }, 500);
        }

      } else if (!res.success && res.action === 'personRegister') {

        if (res.error.status === 500) {

          setError('Error al crear una cuenta, contacta a soporte.');

        } else if (res.error.status === 400) {

          setError(res?.error?.data?.errors[0]?.errorMessage);

        } else if (res && res.error && res.error.data && res.error.data.errors && res.error.data.errors[0].errorMessage) {

          if (res.error.data.errors[0].errorMessage.includes(':')) {

            const mensaje = res.error.data.errors[0].errorMessage.split(':');

            if (mensaje[1].trim() === 'ERROR') {
              setError('Ha ocurrido un error.');
            } else {
              setError(mensaje[1].trim());
            }

          } else {
            setError(res.error.data.errors[0].errorMessage);
          }

        } else {
          setError('Ha ocurrido un error.');
        }

      }
    });


  }

  console.debug('[[AUTH ERROR]]', error)

  return (
    <AuthUI
      type={authModal.type}
      onClose={handleClose}
      changeView={handleChangeView}
      handleSignin={handleLogin}
      handleSignup={handleSignup}
      error={error}
      />
  )

}


export default withRouter(observer(Auth));


// export default inject('renewalStore', 'sessionStore', 'accountStore')(observer(withRouter(Auth)));
