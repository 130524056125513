import { action, observable, makeObservable } from 'mobx';

// Helpers
import { getSeoConfigMC, queryFunction } from '~/store/Query/api';


class SeoStore {
  getSeoConfig = observable.map();

  constructor() {
    makeObservable(this, {
      getSeoConfig: observable,
      getSeoConfigQuery: action
    });
  }

  async getSeoConfigQuery() {
    const response = await queryFunction('config', getSeoConfigMC);
    this.getSeoConfig = response;
    return response;
  }
}

export default new SeoStore();
