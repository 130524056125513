import { inject, observer } from 'mobx-react';
import React, { useState } from 'react';
import Notification from '~/containers/Views/Notification/Notification.ui';
import '../../sass/modules/_auth.scss';
import Button from '../Button/Button';
import { Input } from '../Input/Input';
import { recoverValidations } from './validations';


const Recover = ({
  goToSignin = () => {},
  onSubmitForm = (payload) => {},
  error = null,
  accountStore,
}) => {

  const [ presented, setPresented ] = useState(false);
  const [ errors, setErrors ] = useState({});
  const [ formState, setFormState ] = useState({
    email: '',
    lastName: '',
    email: '',
    password: '',
    confirmPassword: '',
    termsAndConditions: false,
  });

  const handleInputChange = (field, value) => {

    setFormState({
      ...formState,
      [field]: value,
    });
  }

  const onSubmit = () => {

    const formValidation = recoverValidations(formState);

    if (formValidation.hasErrors) {
      setErrors(formValidation.errors);
      return;
    }

    setErrors({});
    setPresented(true);
    accountStore.resetPassword({ email: formState.email });

  }

  const success = presented && accountStore.resetPass && accountStore.resetPass.success;


  return (
    <React.Fragment>

      <div className='auth-header'>
        <h1 className='auth-header--title'>Recuperar Contraseña</h1>
        <div className='auth-header--divider-yellow'></div>
        <p className='auth-header--description'>
          Ingresa un correo que puedas comprobar ahora
        </p>
      </div>

      <div className='auth-modal-content-card--signup'>

        <div className="auth-modal-content-body">

          {
            !presented && (
              <React.Fragment>
                <div className='login-form'>

                  <Input 
                    label="Correo electrónico" 
                    name='email' 
                    onChange={v => handleInputChange('email', v)} 
                    value={formState.email} 
                    error={errors?.email} 
                    />

                </div>

                <div className="auth-modal-actions-buttons">

                  <Button 
                    color="red"
                    onClick={onSubmit} 
                    autoWidth="100%" 
                    appareance="primary" 
                    customStyles={{ fontWeight: 700 }} 
                    >
                    Siguiente
                  </Button>
                </div>
                <div className='go-to-signin'>
                  <span className='go-to-signin--text'>¿Ya tienes una cuenta?</span>
                  <span className='go-to-signin--link' onClick={goToSignin}>Iniciar Sesión</span>
                </div>
              </React.Fragment>
            )
          }

          {
            presented && success && (
              <React.Fragment>
                <div className='login-form'>

                  <div>
                    <h4 style={{ color: '#da291c', fontSize: '24px', fontWeight: 500 }}>Contraseña Restablecida</h4>
                    <br />
                    <p style={{ color: '#da291c', fontSize: '16px', fontWeight: 500 }}>Se ha enviado la contraseña al correo:</p>
                    <p style={{ color: '#da291c', fontSize: '16px', fontWeight: 500 }}>{formState.email}</p>
                  </div>

                </div>
                <br />
                <div className="auth-modal-actions-buttons">

                  <Button 
                    color="red"
                    onClick={goToSignin} 
                    autoWidth="100%" 
                    appareance="primary" 
                    customStyles={{ fontWeight: 700 }} 
                    >
                    Iniciar Sesión
                  </Button>
                </div>
              </React.Fragment>
            )
          }

          {
            presented && !success && (
              <React.Fragment>
                <div className='login-form'>

                {accountStore && accountStore.resetPass && accountStore.resetPass.error ? <Notification title='Servicio no disponible'/> : ''}
                {accountStore && accountStore.resetPass && accountStore.resetPass.data ? <Notification title='Ese usuario no existe o se ha intentado demasiadas veces a las permitidas.'/> : ''}

                </div>
                <br />
                <div className="auth-modal-actions-buttons">

                  <Button 
                    color="red"
                    onClick={goToSignin} 
                    autoWidth="100%" 
                    appareance="primary" 
                    customStyles={{ fontWeight: 700 }} 
                    >
                    Iniciar Sesión
                  </Button>
                </div>
              </React.Fragment>
            )
          }





        </div>

      </div>
    </React.Fragment>
  )

}

export default inject('accountStore')(observer(Recover));