import React, { useState } from 'react';
import '../../sass/modules/_auth.scss';
import Button from '../Button/Button';
import { Input } from '../Input/Input';
import { signinValidations } from './validations';
import Notification from '~/containers/Views/Notification/Notification.ui';




const SigninForm = ({
  goToSignup = () => {},
  onSubmitForm = () => {},
  error = null,
  goToPasswordRecover = () => {},
}) => {

  const [ errors, setErrors ] = useState({});
  const [ formState, setFormState ] = useState({
    email: '',
    password: '',
  });


  const handleInputChange = (field, value) => {

    setFormState({
      ...formState,
      [field]: value,
    });
  }

  const onSubmit = () => {

    const formValidation = signinValidations(formState);

    if (formValidation.hasErrors) {
      setErrors(formValidation.errors);
      return;
    }

    setErrors({});
    onSubmitForm(formState);

  }

  return (
    <React.Fragment>
      <div className='auth-header'>
        <h1 className='auth-header--title'> Inicia sesión </h1>
        <div className='auth-header--divider-yellow'></div>
        <p className='auth-header--description'>
          Recuerda que puedes utilizar la misma cuenta y contraseña de Mi Claro para acceder a Tienda en Línea
        </p>
      </div>

      <div className='auth-modal-content-card'>

        <div className="auth-modal-content-body">

          <div className='login-form'>
            <Input name='email' label="Ingresa correo electrónico" onChange={e => handleInputChange('email', e)} value={formState.email} error={errors?.email} />
            <Input name='password' type='password' label="Contraseña" onChange={e => handleInputChange('password', e)} value={formState.password} error={errors?.password} />
          </div>

          <div className='password-recover'>
            <span className='password-recover--text' onClick={goToPasswordRecover}>
              ¿Olvidaste la contraseña?
            </span>
          </div>

          <div className="auth-modal-actions-buttons">
            <Button
              color="red"
              autoWidth="100%"
              onClick={onSubmit}>
              Ingresar
            </Button>
            <div className='create-acount'>
              <span className='create-acount--text'>
                ¿No tienes cuenta con nosotros?
              </span>
            </div>
            <Button appareance="ghost" onClick={goToSignup} autoWidth="100%" color="red">
              Registrate
            </Button>
          </div>

          <div>
            { error && <Notification title={error} /> }
          </div>
        </div>

      </div>
    </React.Fragment>
  )

}

export default SigninForm;
